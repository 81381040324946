const globalHistory = require("@reach/router").globalHistory
const throttle = require("lodash/throttle")

const getWindowDimensions = () => {
  if (typeof window !== "undefined") {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    return {
      height,
      width,
    }
  }

  return {
    width: 0,
    height: 0,
  }
}

function handleAccessibilityFocus() {
  const elementsWithA11yFocus = Array.from(
    document.querySelectorAll("[data-a11y]")
  )

  document.addEventListener("keyup", event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute("data-a11y", "true")
      } else {
        element.setAttribute("data-a11y", "false")
      }
    })
  })

  // On mouse click change data-a11y attribute false
  document.addEventListener("mousedown", event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute("data-a11y", "false")
      }
    })
  })
}

/**
 * handleFadeInAndOutOnScroll()
 * Attaches a scroll event listener to the window and will query for each
 * How to use:
 * <Element data-scroll-fade{true} />
 *
 */
function handleFadeInAndOutOnScroll() {
  const clamp = value => Math.min(Math.max(value, 0), 1)

  const handleScroll = throttle(() => {
    const { height } = getWindowDimensions()
    const elements = Array.from(document.querySelectorAll("[data-scroll-fade]"))

    elements.forEach(element => {
      const box = element.getBoundingClientRect()

      if (box.top < height / 3.33) {
        // Fade out the element when it reaches the top 2/3 of the page
        element.style.opacity = clamp(
          (box.top + element.offsetHeight / 1.5) / (height / 3.33)
        )
      } else {
        // Fade in the element from the bottom of the page
        element.style.opacity = clamp((1 - box.top / height) * 1.66)
      }
    })
  }, 20)

  window.addEventListener("scroll", handleScroll)
}

/**
 * handleBackgroundChangeOnScroll()
 * Attaches a scroll event listener to the window and will query for each
 * How to use:
 * <Element data-scroll-fade{true} />
 *
 */
function handleBackgroundChangeOnScroll() {
  const clamp = value => Math.min(Math.max(value, 0), 1)

  //const handleScroll = throttle(() => {
  const handleScroll = () => {
    const { height } = getWindowDimensions()
    const elements = Array.from(document.querySelectorAll("[data-scroll-bg]"))

    const bg = document.querySelectorAll(".main")[0]

    elements.forEach(element => {
      const box = element.getBoundingClientRect()

      if (box.top < height / 3.33) {
        //currentBGColor = element.getAttribute('data-scroll-bg');
        const classes = bg.className
          .split(" ")
          .filter(c => !c.startsWith("bg-"))
        bg.className = classes.join(" ").trim()
        const bgClass = element.getAttribute("data-scroll-bg")
        bg.classList.add(bgClass)
        //bg.style.backgroundColor = element.getAttribute('data-scroll-bg');
      } else {
        //bg.style.backgroundColor = "yellow";
      }
    })
  }
  //}, 20)

  window.addEventListener("scroll", handleScroll)
}

module.exports = async () => {
  handleAccessibilityFocus()
  handleFadeInAndOutOnScroll()
  // handleBackgroundChangeOnScroll()

  /**
   * This is a workaround for a bug in Gatsby
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete()

  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
    console.log("IntersectionObserver polyfilled ;)")
  }

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  window.addEventListener(
    "resize",
    throttle(() => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }, 100)
  )
}
